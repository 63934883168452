<template>
  <div class="influencer">
    <div class="container">
      <div class="topHead">
        <navigation />
        <div class="filterWrapper">
          <base-locations v-model="locObject"></base-locations>
          <base-media-accounts v-model="socialObject"></base-media-accounts>
        </div>
      </div>
      <div v-if="$apollo.loading && this.isFirst">
        <base-skeleton-loader
          type="influencer"
          :count="this.limit"
          v-if="$apollo.queries.influencers.loading && isFirst"
        ></base-skeleton-loader>
      </div>
      <div class="row" v-else>
        <div
          class="col-xl-3 col-lg-4 col-sm-6"
          v-for="influencer in influencers"
          :key="influencer.id"
        >
          <influencer-card :influencer="influencer" />
        </div>
        <infinite-loading
          @infinite="infiniteHandler"
          :identifier="infiniteId"
          :distance="30"
          v-if="!isFirst"
        >
        </infinite-loading>
      </div>
      <div
        class="no-record"
        v-if="influencers.length == 0 && !$apollo.loading.influencers"
      >
        No Record Found
      </div>
    </div>
  </div>
</template>

<script>
import { INFLUENSER } from "@/graphql/brand/query";
import { mapGetters } from "vuex";
export default {
  components: {
    influencerCard: () =>
      import(
        /* webpackChunkName: "influencerCard" */ "@/components/brand/influencers/InfluencerCard.vue"
      ),
    navigation: () =>
      import(
        /* webpackChunkName: "navigation" */ "@/components/brand/Navigation.vue"
      ),
  },
  computed: {
    ...mapGetters({
      mediaAccounts: "mediaAccounts/list",
      locations: "locations",
    }),
  },
  data() {
    return {
      influencers: [],
      isFirst: true,
      location_id: null,
      account_id: null,
      page: 1,
      limit: 12,
      infiniteId: +new Date(),
      hasMore: true,
      locObject: null,
      socialObject: null,
    };
  },
  apollo: {
    influencers: {
      query: INFLUENSER,
      variables() {
        return {
          page: 1,
          limit: this.limit,
          location_id: this.location_id,
          account_id: this.account_id,
        };
      },
    },
  },
  watch: {
    influencers() {
      this.isFirst = false;
    },
    locObject(location) {
      if (location) {
        this.location_id = parseInt(location.id);
        this.hasMore = true;
        this.infiniteId += 1;
      }
    },
    socialObject(account) {
      if (account) {
        this.account_id = parseInt(account.value);
        this.hasMore = true;
        this.infiniteId += 1;
      }
    },
  },
  methods: {
    infiniteHandler($state) {
      if (this.hasMore) {
        setTimeout(() => {
          try {
            this.page++;
            this.$apollo.queries.influencers.fetchMore({
              variables: {
                page: this.page,
                limit: this.limit,
                location_id: this.location_id,
                account_id: this.account_id,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const newInfluencers = fetchMoreResult.influencers;
                if (newInfluencers.length) {
                  $state.loaded();
                } else {
                  $state.complete();
                  this.hasMore = false;
                }
                if (newInfluencers.length < this.limit) {
                  this.hasMore = false;
                }
                return {
                  influencers: [
                    ...previousResult.influencers,
                    ...newInfluencers,
                  ],
                };
              },
            });
          } catch (err) {
            $state.error();
          }
        }, 500);
      } else {
        $state.complete();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.influencer {
  ::v-deep {
    .filters {
      margin-bottom: 0;
      padding-top: 0;
      @media screen and (max-width: 575px) {
        width: 100%;
        .d-flex {
          flex-direction: column;
          align-items: center;
          width: 100%;
          .form-group {
            &:not(:last-child) {
              margin-bottom: rem(20px);
            }
          }
          .multiselect {
            margin: 0;
            min-width: 300px;
          }
        }
      }
    }
  }
  .topHead {
    margin-bottom: rem(18px);
  }
}
</style>
